import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import SEO from "../components/seo";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import BenefitBlock from "../components/benefitBlock";
import { FaCheck } from "react-icons/fa";
import Columns from "../components/columns";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import Tile from "../components/Tile";
import Container from "../components/container";
import { Link } from "gatsby";
import * as styles from "./index.module.css";

export const query = graphql`
  {
    aura: file(relativePath: { eq: "logo-aura.png" }) {
      childImageSharp {
        gatsbyImageData(width: 500, layout: CONSTRAINED)
      }
    }
    automico: file(relativePath: { eq: "automicob2b-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 500, layout: FULL_WIDTH)
      }
    }
    laptop: file(relativePath: { eq: "samoobsluga-b2b.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const B2BSelfService = ({ data }) => {
  return (
    <FunctionPageLayout
      title="B2B self-service"
      headline="Automate sales processes"
    >
      <SEO
        title="B2B self-service - B2B platforms - automate sales"
        description="B2B self-service ✅ B2B platforms • Dedicated and complementary to ERP e-commerce solutions, a standard B2B platform with many functions"
        keywords={["B2B self-service"]}
      />

      <p>
        In the area of sales automation we offer two B2B platforms. The first
        one is a{" "}
        <strong>
          dedicated solution that, apart from the sales platform, can provide
          many other possibilities
        </strong>
        , allowing for comprehensive self-service of business clients as well as
        management of the entire company. The second one is a{" "}
        <strong>
          ready-to-use system that responds to the standard needs of wholesale
          companies
        </strong>{" "}
        and which implementation is simpler and faster. Their most important
        feature is flexibility, and ours -{" "}
        <strong>experience in implementing projects</strong> for many companies
        in various industries.
      </p>
      <p>
        We know that companies at different stages of development need different
        solutions, which is why we have created two different approaches.
      </p>
      <br />
      <br />
      <Columns>
        <div>
          <h3>Dedicated B2B platform</h3>
          <p>
            <strong>Aura Business</strong> is a large, complementary to ERP{" "}
            <strong>
              tool supporting sales, marketing and service management
            </strong>
            . It has numerous configuration and integration possibilities. The
            system is designed for companies that have non-standard needs.{" "}
            <strong>Aura Business is not only an e-commerce solution</strong>.
            The system has many other modules that we can adapt to your company
            and that will allow comprehensive management of your business.
          </p>
        </div>

        <div>
          <br />
          <br />
          <br />
          <br />
          <a href="https://aurabusiness.com/">
            <GatsbyImage
              image={data.aura.childImageSharp.gatsbyImageData}
              style={{
                width: "100%",
                margin: "0 auto",
                maxWidth: 300,
              }}
              alt="Aura Business"
            />
          </a>
          <Container>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <a className={styles.startNow} href="https://aurabusiness.com/">
                Learn more about Aura Business
              </a>
            </div>
          </Container>
        </div>
      </Columns>
      <br />
      <br />

      <br />
      <br />

      <Columns>
        <div>
          <h3>Ready-to-go system with integrations - Automico B2B</h3>
          <p>
            <strong>Automico B2B</strong> is a ready-to-use solution that will
            automate your sales. It will allow your customers to{" "}
            <strong>shop independently 24 hours a day, 7 days a week</strong>.
            It will reduce the costs of communication with the customer. Your
            customers will have{" "}
            <strong>
              access to an attractive and current product offer, information on
              invoices and settlements, promotions and individual commercial
              conditions
            </strong>
            . The platform cooperates with sales and warehouse management
            software.
          </p>
        </div>

        <div>
          <br />
          <br />
          <br />
          <br />
          <a href="https://automicob2b.pl/">
            <GatsbyImage
              image={data.automico.childImageSharp.gatsbyImageData}
              style={{
                width: "100%",
                margin: "0 auto",
                maxWidth: 300,
              }}
              alt="Automico B2B"
            />
          </a>
          <Container>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <a className={styles.startNow} href="https://automicob2b.pl/">
                Learn more about Automico B2B
              </a>
            </div>
          </Container>
        </div>
      </Columns>
      <br />
      <br />
      <center>
        <h4>
          Are you interested in our B2B platforms? Contact us. We'd be happy to
          talk about the development opportunities for your business.
        </h4>
        <Container>
          <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/contact/">
              Contact us
            </Link>
          </div>
        </Container>
      </center>
    </FunctionPageLayout>
  );
};

export default B2BSelfService;
